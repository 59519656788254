import { FC, useState } from "react";
import { Box, Button, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { GrantVestment, Links } from "types/grant-vestment";
import CustomTabPanel from "components/shared/custom-tab-panel";
import GrantVestmentRequestsList from "./grant-vestment-requests-list";

interface GrantVestmentsDetailsTabsProps {
  grantvestment: GrantVestment;
  isAuthenticated: boolean;
  handleOpen: (value: boolean) => void;
  track: (type: string, name: string, link: string) => void;
  user: string;
}

const GrantVestmentsDetailsTabs: FC<GrantVestmentsDetailsTabsProps> = ({
  grantvestment,
  isAuthenticated,
  handleOpen,
  track,
  user,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  const userNotSignedIn = () => {
    return (
      <Box sx={{ textAlign: "center", my: 3 }}>
        <Typography
          sx={{ color: "neutral.main" }}
          variant="subtitle3"
          gutterBottom
          paragraph
        >
          Set up a Neta DAF to access Data room and Updates
        </Typography>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleOpen(true)}
        >
          Sign Up
        </Button>
        <Typography
          sx={{ color: "neutral.main" }}
          variant="bodyCopyRegular"
          gutterBottom
          paragraph
        >
          Already have an account?{" "}
          <Typography variant="bodyCopyBold" sx={{ color: "primary.main" }}>
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleOpen(false);
              }}
              underline="always"
              color={"inherit"}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Typography>
      </Box>
    );
  };
  const noData = () => {
    return (
      <Box sx={{ textAlign: "center", my: 9 }}>
        <Typography
          sx={{ color: "neutral.main" }}
          variant="subtitle3"
          gutterBottom
          paragraph
        >
          There’s no data available about this project
        </Typography>
      </Box>
    );
  };
  const isUserAuthorized = () => {
    const subs = grantvestment.grantAuthorizedViewers?.map(
      (viewer) => viewer.user.sub
    );
    return subs.includes(user);
  };
  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        className="grantvestment"
      >
        <Tab label="About" className="grantvestment" />
        <Tab label="Data room " className="grantvestment" />
        <Tab label="Updates" className="grantvestment" />
        <Tab label="My requests" className="grantvestment" />
      </Tabs>
      <CustomTabPanel value={currentTabIndex} index={0}>
        <div data-color-mode="light">
          {" "}
          <MDEditor.Markdown
            style={{
              backgroundColor: "transparent",
              whiteSpace: "pre-wrap",
              maxWidth: "1050px",
              padding: "5px",
            }}
            source={grantvestment?.about}
          />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={currentTabIndex} index={1}>
        {isAuthenticated ? (
          grantvestment?.dataRoom && grantvestment?.dataRoom.length === 0 ? (
            noData()
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {grantvestment?.dataRoom &&
                  grantvestment?.dataRoom
                    .slice(0, Math.ceil(grantvestment?.dataRoom.length / 2))
                    .map((item: Links, index) => {
                      return (
                        <Typography
                          key={index - 1}
                          variant="bodyCopyBold"
                          paragraph
                        >
                          <Link
                            onClick={() =>
                              track("dataRoom", item.title, item.url)
                            }
                            href={item.url}
                            target="_blank"
                            underline="always"
                            color={"inherit"}
                            sx={{
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            {item.title}
                          </Link>
                        </Typography>
                      );
                    })}
              </Grid>
              <Grid item xs={12} sm={6}>
                {grantvestment?.dataRoom &&
                  grantvestment?.dataRoom
                    .slice(Math.ceil(grantvestment?.dataRoom.length / 2))
                    .map((item, index) => {
                      return (
                        <Typography
                          key={index - 0}
                          variant="bodyCopyBold"
                          paragraph
                        >
                          <Link
                            onClick={() =>
                              track("dataRoom", item.title, item.url)
                            }
                            href={item.url}
                            target="_blank"
                            underline="always"
                            color={"inherit"}
                            sx={{
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            {item.title}
                          </Link>
                        </Typography>
                      );
                    })}
              </Grid>
            </Grid>
          )
        ) : (
          userNotSignedIn()
        )}
      </CustomTabPanel>
      {
        <CustomTabPanel value={currentTabIndex} index={2}>
          {isAuthenticated && isUserAuthorized() ? (
            grantvestment?.updates && grantvestment?.updates.length === 0 ? (
              noData()
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {grantvestment?.updates &&
                    grantvestment?.updates
                      .slice(0, Math.ceil(grantvestment?.updates.length / 2))
                      .map((item, index) => {
                        return (
                          <Typography
                            key={index - 1}
                            variant="bodyCopyBold"
                            paragraph
                          >
                            <Link
                              href={item.url}
                              onClick={() =>
                                track("updates", item.title, item.url)
                              }
                              target="_blank"
                              underline="always"
                              color={"inherit"}
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {item.title}
                            </Link>
                          </Typography>
                        );
                      })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {grantvestment?.updates &&
                    grantvestment?.updates
                      .slice(Math.ceil(grantvestment?.updates.length / 2))
                      .map((item, index) => {
                        return (
                          <Typography
                            key={index - 0}
                            variant="bodyCopyBold"
                            paragraph
                          >
                            <Link
                              href={item.url}
                              onClick={() =>
                                track("updates", item.title, item.url)
                              }
                              target="_blank"
                              underline="always"
                              color={"inherit"}
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {item.title}
                            </Link>
                          </Typography>
                        );
                      })}
                </Grid>
              </Grid>
            )
          ) : isAuthenticated && !isUserAuthorized() ? (
            <Typography>
              Access to this page has been restricted. Please contact us if you
              want access.
            </Typography>
          ) : (
            userNotSignedIn()
          )}
        </CustomTabPanel>
      }

      <CustomTabPanel value={currentTabIndex} index={3}>
        <GrantVestmentRequestsList
          grantvestmentId={grantvestment.grantVestmentId!}
        />
      </CustomTabPanel>
    </>
  );
};

export default GrantVestmentsDetailsTabs;
